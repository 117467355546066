/*
  This is the 'Token View'. It displays the SLP tokens in the wallet.
*/

// Global npm libraries
import React from 'react'
import { Container, Row, Col, Card, Button, Spinner } from 'react-bootstrap'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons'

// Local libraries
import TokenCard from './token-card'
import RefreshTokenBalance from './refresh-tokens'

let _this

class SlpTokens extends React.Component {
  constructor (props) {
    super(props)

    // Create a reference to the Refresh button.
    this.refreshTokenButtonRef = React.createRef()

    this.state = {
      appData: props.appData,
      iconsAreLoaded: false
    }

    _this = this
  }

  async componentDidMount () {
    await this.lazyLoadTokenIcons()
  }

  render () {
    const tokenCards = this.generateCards()
    // console.log('tokenCards: ', tokenCards)

    return (
      <>
        <Container>
          <Row>
            <Col xs={6}>
              <RefreshTokenBalance
                appData={this.state.appData}
                ref={this.refreshTokenButtonRef}
                lazyLoadTokenIcons={this.lazyLoadTokenIcons}
              />
            </Col>

            <Col xs={4} style={{ textAlign: 'right' }}>
              {
                this.state.iconsAreLoaded
                  ? null
                  : (<Button variant='secondary'>Loading Token Icons <Spinner animation='border' /></Button>)
              }

            </Col>

            <Col xs={2} style={{ textAlign: 'right' }}>
              <a href='https://youtu.be/f1no5-QHTr4' target='_blank' rel='noreferrer'>
                <FontAwesomeIcon icon={faCircleQuestion} size='lg' />
              </a>
            </Col>
          </Row>
          <br />

          <Row>
            {tokenCards}
          </Row>

        </Container>
      </>
    )
  }

  // This function is called by the componentDidMount() lifecycle function.
  // It replaces the autogenerated token icons with proper icons, downloaded
  // from the internet.
  async lazyLoadTokenIcons () {
    // console.log('this.state.appData: ', this.state.appData)
    const tokens = this.state.appData.bchWalletState.slpTokens
    // console.log(`lazy loading these tokens: ${JSON.stringify(tokens, null, 2)}`)

    for (let i = 0; i < tokens.length; i++) {
      const thisToken = tokens[i]
      let tokenFound = false

      // if (thisToken.iconNeedsDownload) {
      //   console.log(`token ${thisToken.tokenId} needs icon download`)
      // }

      // If the URL property of the token has an IPFS CID, then it probably
      // follows the PS002 specification for tokens. Download the token icon
      // and replace the Jdenticon automatically-generated icon.
      if (thisToken.url.includes('ipfs://') && thisToken.iconNeedsDownload) {
        const wallet = this.state.appData.bchWallet

        // Retrieve token data from psf-slp-indexer.
        const tokenData = await wallet.getTokenData(thisToken.tokenId)
        // console.log(`tokenData: ${JSON.stringify(tokenData, null, 2)}`)

        // If the token has mutable data, then try to retrieve it from IPFS.
        if (tokenData.mutableData && tokenData.mutableData.includes('ipfs://')) {
          const cid = tokenData.mutableData.substring(7)
          // console.log('cid')

          // Retrieve the mutable data from Filecoin/IPFS.
          const url = `https://${cid}.ipfs.dweb.link/data.json`
          const result = await axios.get(url)

          const mutableData = result.data
          // console.log(`mutableData: ${JSON.stringify(mutableData, null, 2)}`)

          const tokenIcon = mutableData.tokenIcon

          const newIcon = (
            <Card.Img src={tokenIcon} style={{ width: '100px' }} />
          )

          tokenFound = true

          // Add the JSX for the icon to the token object.
          thisToken.icon = newIcon
        }
      }

      // If the token does not have mutable data to store icon data,
      // Check the slp-token-icon GitHub repository for an icon:
      // https://github.com/kosinusbch/slp-token-icons
      if (!tokenFound && thisToken.iconNeedsDownload) {
        const url = `https://tokens.bch.sx/100/${thisToken.tokenId}.png`
        // console.log('url: ', url)

        // Check to see if icon exists. If it doesn't, axios will throw an error
        // and this function can exit.
        try {
          await axios.get(url)

          const newIcon = (
            <Card.Img src={url} style={{ width: '100px' }} />
          )

          // Add the JSX for the icon to the token object.
          thisToken.icon = newIcon
        } catch (err) {
          /* exit quietly */
        }
      }

      // Signal that a token download has been attempted.
      thisToken.iconNeedsDownload = false
    }

    // Update the state of the wallet with the balances
    this.state.appData.updateBchWalletState({ walletObj: { slpTokens: tokens }, appData: this.state.appData })

    this.setState({
      iconsAreLoaded: true
    })
  }

  // This function is triggered when the token balance needs to be refreshed
  // from the blockchain.
  // This needs to happen after sending a token, to reflect the changed balance
  // within the wallet app.
  // This function triggers the on-click function within the refresh-tokens.js button.
  async refreshTokens () {
    const appData = await _this.refreshTokenButtonRef.current.handleRefreshBalance()

    // await this.lazyLoadTokenIcons()

    // console.log('new appData: ', appData)

    _this.setState({ appData })

    // _this.render()
  }

  // This function generates a Token Card for each token in the wallet.
  generateCards () {
    // console.log(`generateCards() shouldRefreshTokens: ${this.state.shouldRefreshTokens}`)

    const tokens = this.state.appData.bchWalletState.slpTokens

    const tokenCards = []

    for (let i = 0; i < tokens.length; i++) {
      const thisToken = tokens[i]
      // console.log(`thisToken: ${JSON.stringify(thisToken, null, 2)}`)

      const thisTokenCard = (
        <TokenCard
          appData={this.state.appData}
          token={thisToken}
          key={`${thisToken.tokenId}`}
          refreshTokens={this.refreshTokens}
        />
      )
      tokenCards.push(thisTokenCard)
    }

    return tokenCards
  }
}

export default SlpTokens
